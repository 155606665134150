import MultiSelect from "src/Components/MultiSelect/MultiSelect";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import DataTable from "src/Components/DataTable/DataTable";
import {DataTableValue} from "primereact/datatable";
import {
    ApplicativeAccountsProps,
    ServerProps,
} from "../../../Tools/Interfaces";
import {BoManagementContainer} from "../Style";
import {addApplicativesRightsForSelectedAccount, updateApplicativeRightsForSelectedAccount} from "../../../Tools/Api";
import Button from "../../../Components/Button/Button";
import {boRolesArray, isNewAccountAlreadyExist, updateApplicativesRightsOnAccount} from "../../../Tools/Utilities";
import {useUserContext} from "../../../Contexts/UserContext";
import {useParams} from "react-router-dom";
import {toastSuccessUpdatingAccount} from "../../../Tools/Toast";

interface ManageBoRightsProps {
    loginForNewsRights?: string;
}

const ManageBoRights = ({loginForNewsRights}: ManageBoRightsProps) => {
    const {t} = useTranslation();
    const {login} = useParams<{ login: string }>();
    const [serversWithBoRights, setServersWithBoRights] = useState<DataTableValue[]>([]);
    const [selectedBoRoles, setSelectedBoRoles] = useState<{ [key: string]: DataTableValue[] }>({});
    const {casClient, setApplicativeAccounts, applicativeAccounts, serversWithBackOfficeUserRight} = useUserContext();
    const [selectedUser, setSelectedUser] = useState<ApplicativeAccountsProps>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);


    useEffect(() => {
        setSelectedUser(applicativeAccounts.find((user) => user.login === login))
    }, [applicativeAccounts]);

    useEffect(() => {
        const serversWithExistingBoRights: (ServerProps | undefined)[] | undefined = selectedUser?.applicativeRights.accountServersWithBoAccess?.map((server: {
                serverId?: string
            }) => {
                return serversWithBackOfficeUserRight.find((serverConnected: {
                    uuid: string
                }) => serverConnected.uuid === server.serverId)
            }
        )
        setServersWithBoRights(serversWithExistingBoRights as ServerProps[]);
    }, [selectedUser]);

    useEffect(() => {
        const initialBoRoles: { [key: string]: DataTableValue[] } = {};
        selectedUser?.applicativeRights.accountServersWithBoAccess?.forEach((server: {
            serverId?: string,
            buType?: number,
        }) => {
            const serverData = serversWithBackOfficeUserRight.find((_server: {
                uuid: string,
            }) => _server.uuid === server.serverId);
            if (serverData) {
                initialBoRoles[serverData.uuid] = [{
                    role: server.buType,
                    label: boRolesArray.find((role) => role.role === server.buType)?.label || ""
                }];
            }
        });
        setSelectedBoRoles(initialBoRoles)
    }, [selectedUser]);

    const handleSelectRoles = (uuid: string) => (e: DataTableValue) => {
        setSelectedBoRoles(prevSelectedBoRoles => {
            const filteredSelectedBoRoles = Object.entries(prevSelectedBoRoles)
                .filter(([value]) => value && value.length > 0)
                .reduce((obj, [key, value]) => ({...obj, [key]: value}), {});
            if (!e.value || e.value.length === 0) {
                return filteredSelectedBoRoles;
            }
            return {
                ...filteredSelectedBoRoles,
                [uuid]: e.value.slice(-1)
            };
        });
    }
    const columns = [
        {
            id: "actions",
            title: "Roles",
            body: (rowData: ServerProps) => {
                return (
                    <>
                        <div>
                            <MultiSelect value={selectedBoRoles[rowData?.uuid]}
                                         onChange={handleSelectRoles(rowData?.uuid)}
                                         options={boRolesArray} showSelectAll={false}
                                         optionLabel="label" title={rowData?.code}
                                         placeholder={t('general.MultiSelect.noRole')}
                                         maxSelectedLabels={1}
                                         selectionLimit={0}/>
                        </div>
                    </>)
            }
        },
    ];
    const handleUpdateBoRights = () => {
        setIsDisabled(true)
        const selectedServersFiltered = serversWithBoRights.filter(server => {
            return Object.keys(selectedBoRoles).includes(server.uuid);
        });
        const uuidAndRoleForEachServer = selectedServersFiltered.map(server => {
            const roles = selectedBoRoles[server.uuid];
            const buType = roles.map(role => role.role).reduce((acc, curr) => acc + curr, 0)
            return {
                serverId: server.uuid,
                buType: buType
            };
        });
        const dataToUpdate = {
            login: selectedUser?.login || loginForNewsRights,
            applicativeRights: {
                accountServersWithBoAccess: uuidAndRoleForEachServer || [{}]
            }
        };
        const isAccountAlreadyExist = dataToUpdate.login && isNewAccountAlreadyExist(applicativeAccounts, dataToUpdate.login)
        if (isAccountAlreadyExist) {
            updateApplicativeRightsForSelectedAccount(dataToUpdate, casClient.getToken()).then((response) => {
                const updatedApplicativeAccounts = applicativeAccounts.map((user) => {
                    if (!response && user.login === dataToUpdate.login) {
                        return response
                    } else if (user.login === response?.login) {
                        return updateApplicativesRightsOnAccount(response, t)
                    } else {
                        return user;
                    }
                });
                toastSuccessUpdatingAccount(t('general.Toasts.successUpdate'))
                setApplicativeAccounts(updatedApplicativeAccounts as ApplicativeAccountsProps[]);
            }).then(() => {
                setIsDisabled(false);
            });
        } else {
            addApplicativesRightsForSelectedAccount(dataToUpdate, casClient.getToken()).then((response) => {
                    toastSuccessUpdatingAccount(t('general.Toasts.successCreate'))
                    response && setApplicativeAccounts([...applicativeAccounts, updateApplicativesRightsOnAccount(response, t)])
                }
            ).then(() => {
                setIsDisabled(false);
            });
        }
    };
    const handleSelect = (e: DataTableValue) => {
        setServersWithBoRights(e.value);
        if (e.value.length === 0) setSelectedBoRoles({})
    };

    return (
        <BoManagementContainer>
            <h2>{t('general.AccountManagement.titleBo')}</h2>
            <div>
                <MultiSelect
                    value={serversWithBoRights}
                    title={t("general.AccountManagement.selectServer")}
                    options={serversWithBackOfficeUserRight}
                    optionLabel="code"
                    onChange={handleSelect}
                    maxSelectedLabels={1}
                    selectAll={true}
                />
            </div>
            <div className="serverList-container">
                <DataTable
                    columns={columns}
                    data={serversWithBoRights}
                />
            </div>
            <Button icon="pi pi-check" aria-label="Filter" label={t('general.Button.save')} className="button-save"
                    disabled={isDisabled}
                    onClick={handleUpdateBoRights}/>
        </BoManagementContainer>
    );
};

export default ManageBoRights;
export const APP_PATH = {
    ROOT: '/',
    CALLBACK: '/callback',
    AUTH: '/auth',
    LOGIN: '/login',
    LOGOUT: '/logout',
    APPLICATIVE_SERVERS_MANAGEMENT: '/applicative-servers-management/:serverId',
    APPLICATIVE_ACCOUNTS_MANAGEMENT: '/applicative-accounts-management/:login',
    BACKOFFICE_ACCOUNTS: '/backoffice-accounts',
    SET_OR_RESET_PASSWORD: '/set-or-reset-password',
    APPLICATION_ACCESS: '/application-access',
    PASSWORD_MODIFIED: '/password-modified',
    LINK_EXPIRED: '/link-expired',
};

export const AUTH_PATH = '/#/auth';
export const LOGIN = 'back-office.login';
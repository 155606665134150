import {
    ApplicativeAccountsProps,
    AccountServerProps
} from "../../../Tools/Interfaces";
import {BoManagerContainer} from "../Style";
import Button from "../../../Components/Button/Button";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import MultiSelect from "../../../Components/MultiSelect/MultiSelect";
import {DataTableValue} from "primereact/datatable";
import {ChangeEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {updateApplicativeRightsForSelectedAccount} from "../../../Tools/Api";
import AddRightsToAccount from "./AddRightsToAccount";
import Input from "../../../Components/Input/Input";
import {isListEmpty, boRolesArray, updateApplicativesRightsOnAccount} from "../../../Tools/Utilities";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Contexts/UserContext";
import {toastSuccessDeleteAccount, toastSuccessUpdatingAccount} from "../../../Tools/Toast";


const AccountsWithBoRights = () => {
    const {serverId} = useParams<{ serverId: string }>();
    const [accountsWithBoRightsOnSelectedServer, setAccountsWithBoRightsOnSelectedServer] = useState<DataTableValue[]>([]);
    const [checkedAccounts, setCheckedAccounts] = useState<DataTableValue[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const {t} = useTranslation();
    const {casClient, setApplicativeAccounts, applicativeAccounts} = useUserContext();


    useEffect(() => {
        const accountsWithBoRightsOnSelectedServer = applicativeAccounts?.filter((account: ApplicativeAccountsProps) => {
            return account.applicativeRights.accountServersWithBoAccess.find((server: {
                serverId?: string
            }) => server.serverId === serverId);
        });
        setAccountsWithBoRightsOnSelectedServer(accountsWithBoRightsOnSelectedServer)
    }, [applicativeAccounts])
    const handleSelect = (e: CheckboxChangeEvent) => {
        let _checkedAccounts = [...checkedAccounts];
        if (e.checked) {
            _checkedAccounts.push(e.value);
        } else {
            _checkedAccounts = _checkedAccounts.filter((account) => account.login !== e.value.login);
        }
        setCheckedAccounts(_checkedAccounts)
    }


    const handleUpdateBoRights = (e: DataTableValue, account: DataTableValue, serverId?: string) => {
        const indexOfServer = account?.applicativeRights?.accountServersWithBoAccess?.findIndex((server: {
            serverId?: string
        }) => server.serverId === serverId);
        account?.applicativeRights?.accountServersWithBoAccess?.splice(indexOfServer, 1, {
            serverId: serverId,
            buType: e?.value[1]?.role
        });
        const accountToUpdate = {
            login: account.login,
            applicativeRights: {
                accountServersWithBoAccess: account.applicativeRights.accountServersWithBoAccess
            }
        }
        if (account) {
            setAccountsWithBoRightsOnSelectedServer([...accountsWithBoRightsOnSelectedServer])
            updateApplicativeRightsForSelectedAccount(accountToUpdate, casClient.getToken()).then(() =>
                toastSuccessUpdatingAccount(t('general.Toasts.successUpdate'))
            )
        }
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = accountsWithBoRightsOnSelectedServer?.filter((account: unknown) => {
        return account && Object.values(account).some(
            (value) =>
                typeof value === "string" && value.toLowerCase().includes(searchTerm)
        );
    });

    const accountToDeleteBoRights = checkedAccounts.map((account) => {
        return {
            login: account.login,
            applicativeRights: {
                accountServersWithBoAccess: account.applicativeRights.accountServersWithBoAccess.filter((server) => server.serverId !== serverId)
            }
        }
    });

    const handleDeleteBoRights = () => {
        const updatedApplicativeAccounts = applicativeAccounts.map(account => {
            const accountToDeleteRights = accountToDeleteBoRights.find(acc => acc.login === account.login);
            if (accountToDeleteRights) {
                const updatedServersWithBoAccess = account.applicativeRights.accountServersWithBoAccess.filter(server => server.serverId !== serverId);
                return updateApplicativesRightsOnAccount({
                    ...account,
                    applicativeRights: {
                        accountServersWithBoAccess: updatedServersWithBoAccess,
                        accountServersWithBmsAccess: account.applicativeRights.accountServersWithBmsAccess
                    }
                }, t);
            }
            return account;
        });
        setApplicativeAccounts(updatedApplicativeAccounts as ApplicativeAccountsProps[]);
        accountToDeleteBoRights.forEach(accountWithBoRights => {
            updateApplicativeRightsForSelectedAccount(accountWithBoRights, casClient.getToken()).then(() => {
                toastSuccessDeleteAccount(t('general.Toasts.successDelete'));
            });
        });
        setCheckedAccounts([])
    }
    const handleCheckedAllOrNone = () => {
        if (checkedAccounts.length > 0) {
            setCheckedAccounts([]);
        } else {
            setCheckedAccounts(filteredData);
        }
    }

    return (
        <BoManagerContainer>
            <h3>{t('general.ServerManagement.titleBo')}</h3>
            <div className="inputs-container">
                <AddRightsToAccount serverId={serverId}
                                    setAccountsWithBoRightsOnSelectedServer={setAccountsWithBoRightsOnSelectedServer}
                                    accountsWithBoRightsOnSelectedServer={accountsWithBoRightsOnSelectedServer}
                                    setSearchTerm={setSearchTerm}/>
                <div className="search-container">
                    <Input className="input-search" placeholder={t('general.Input.search')} value={searchTerm}
                           onChange={handleSearch}
                           icon="pi pi-search"/>
                </div>
            </div>
            {filteredData?.length > 0 ?
                (<>
                        <div className='userlist-container'>
                            <div>
                                <Checkbox checked={!isListEmpty(checkedAccounts)}
                                          onChange={handleCheckedAllOrNone}/>
                            </div>
                            <div className="accountsListContainer">
                                {filteredData?.map((account) => {
                                    const rightsOnServer = account.applicativeRights.accountServersWithBoAccess.filter((server: AccountServerProps) => server.serverId === serverId
                                    );
                                    const buRoleByAccount = boRolesArray.filter((role) => role.role === rightsOnServer[0]?.buType)
                                    return (
                                        <ul className="accounts-list" key={account.login}>
                                            <li key={account.login} className="account">
                                                <MultiSelect
                                                    title={account.login}
                                                    showSelectAll={false}
                                                    value={buRoleByAccount}
                                                    options={boRolesArray}
                                                    optionLabel="label"
                                                    maxSelectedLabels={1}
                                                    onChange={(e) => handleUpdateBoRights(e, account, serverId)}/>
                                                <Checkbox key={account.login} inputId={account.login} value={account}
                                                          id={account.login}
                                                          name={'server'}
                                                          onChange={handleSelect}
                                                          checked={checkedAccounts.some((_account) => _account.login === account.login)}
                                                />
                                            </li>
                                        </ul>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div>
                                <Button icon="pi pi-check" aria-label="Filter" label={t('general.Button.delete')}
                                        className="button-delete"
                                        onClick={handleDeleteBoRights} disabled={isListEmpty(checkedAccounts)}/>
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <div>
                        <p>{t('general.ServerManagement.noUser')}</p>
                    </div>)
            }
        </BoManagerContainer>
    );
}


export default AccountsWithBoRights;
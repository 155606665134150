import React from "react";
import {useLocation} from "react-router-dom";
import {FullPageContainer, LandingPageContainer, Line} from "./Style";
import heroBanner from "../../Assets/Images/hero-banner.svg";
import heroBanner1Blue from "../../Assets/Images/hero-banner-01-blue.svg";
import heroBanner1Green from "../../Assets/Images/hero-banner-01-green.svg";
import heroBanner2Blue from "../../Assets/Images/hero-banner-02-blue.svg";
import heroBanner2Green from "../../Assets/Images/hero-banner-02-green.svg";
import heroBanner3Blue from "../../Assets/Images/hero-banner-03-blue.svg";
import heroBanner3Green from "../../Assets/Images/hero-banner-03-green.svg";
import heroBanner4Blue from "../../Assets/Images/hero-banner-04-blue.svg";
import heroBanner4Green from "../../Assets/Images/hero-banner-04-green.svg";
import heroBanner5Blue from "../../Assets/Images/hero-banner-05-blue.svg";
import heroBanner5Green from "../../Assets/Images/hero-banner-05-green.svg";
import dyduLogo from "../../Assets/Images/dydu_logo_full.png";
import {useTranslation} from "react-i18next";

//Can access only by redirect from <setOrResetPassword>
const ApplicationAccess: React.FC = () => {
    const location = useLocation();
    const accessRightEnumMap = location.state?.accessRightEnumMap || {};
    const currentYear = new Date().getFullYear();
    const {t} = useTranslation();

    if (Object.keys(accessRightEnumMap).length === 0) {
        // page called manually
        return <></>;
    }
    return (
        <FullPageContainer>
            <div className="afterResetPassword">
                <div className="logo">
                    <img src={dyduLogo} height="69" width="150"
                         alt="DYDU" className="flex1"/>
                </div>
                <h3>{t('general.PasswordManagement.ApplicationAccess.rightsAdded')}</h3>
                {Object.keys(accessRightEnumMap).map((key: string) => {
                    let displayText = '';

                    switch (key) {
                        case 'BO':
                            displayText = t('general.PasswordManagement.ApplicationAccess.bo');
                            break;
                        case 'BMS':
                            displayText = t('general.PasswordManagement.ApplicationAccess.bms');
                            break;
                        case 'BACK_OFFICE_MANAGER':
                            displayText = t('general.PasswordManagement.ApplicationAccess.backOfficeManager');
                            break;
                        case 'BACK_OFFICE_USER':
                            displayText = t('general.PasswordManagement.ApplicationAccess.backOfficeUser');
                            break;
                        default:
                            displayText = `inconnu (${key})`;
                            break;
                    }
                    return (
                        <div >
                            <h4>{t('general.PasswordManagement.ApplicationAccess.right')} {displayText} :</h4>
                            <ul key={key} className="scrollable-content">

                                {accessRightEnumMap[key].map((server: string) => (
                                    <li key={server}>
                                        <a href={`https://${server}/website`} target="_blank" rel="noopener noreferrer">
                                            https://{server}/website
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
                <footer>
                    {t('general.PasswordManagement.ApplicationAccess.footer1')}{currentYear}{t('general.PasswordManagement.ApplicationAccess.footer2')}
                </footer>
            </div>
            <Line>
                <svg preserveAspectRatio="none" height="100%" width="50" version="1.1" viewBox="0 720 48 1440" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 48,2880 V 1.05e-5 H 0 V 720.00001 C 110,1437.5 0,2160 0,2160 v 720 z"></path>
                </svg>
            </Line>
            <LandingPageContainer>
                <div className="landinganimation">
                    <figure className="hero__figure">
                        <img src={heroBanner} height="326" width="353"
                             alt="image dydu software"/>
                        <div className="animation-bulles animation-1">
                            <img className="animation-bulles-blue" src={heroBanner1Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner1Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-2">
                            <img className="animation-bulles-blue" src={heroBanner2Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner2Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-3">
                            <img className="animation-bulles-blue" src={heroBanner3Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner3Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-4">
                            <img className="animation-bulles-blue" src={heroBanner4Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner4Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-5">
                            <img className="animation-bulles-blue" src={heroBanner5Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner5Green}
                                 alt="Bubble green"/>
                        </div>
                    </figure>
                </div>
            </LandingPageContainer>
        </FullPageContainer>
    );
};

export default ApplicationAccess;

import axios, {AxiosResponse} from "axios";
import {APP_PATH} from "./Constants";
import {Navigate} from "react-router-dom";
import {ApplicativeAccountsProps, ApplicativeUpdateRightsProps, BackOfficeAccountProps} from "./Interfaces";
import {toastError} from "./Toast";

export const casUrl = window.ENV ? window.ENV.cas_url : process.env.cas_url || '';
const url_gateway = window.ENV ? window.ENV.gateway_url : process.env.gateway_url;

// base64 of {"appWelcomeSentence":"cas.login.welcome.back-office"}
export const casLoginMessage = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5iYWNrLW9mZmljZSJ9';
export const casClientName = 'BackOfficeCasClient';
export const serverCode = 'multiserver';
export const encodedServiceUrl = encodeURIComponent(window.location.origin + '/?client_name=' + casClientName);

export const getApplicativeAccounts = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/account/applicative-rights/all", {headers});
        return res.data
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
};

// method to get all servers where requesting user has backoffice user rights
export const getServersWithBackOfficeUserRight = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/server/backoffice-user-right/all", {headers});
        return res.data;
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const getServersWithBackOfficeManagerRight = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/server/backoffice-manager-right/all", {headers});
        return res.data;
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
}
export const getBackOfficeAccounts = async (token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.get(url_gateway + "/multi-server/account/backoffice-rights/all", {headers});
        return res.data;
    } catch (error) {
        <Navigate to={APP_PATH.ROOT}/>;
    }
};

export const deleteBackOfficeAccount = async (login: string, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.delete(url_gateway + `/multi-server/account/backoffice-rights/by-login/${login}`, {headers});
        return res.data
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const updateBackOfficeRightsForSelectedAccount = async (data: BackOfficeAccountProps, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<BackOfficeAccountProps> = await axios.put(url_gateway + `/multi-server/account/backoffice-rights/update`,
            data
            , {headers});
        return res.data
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const addBackOfficeRightsForSelectedAccount = async (data: BackOfficeAccountProps, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<BackOfficeAccountProps> = await axios.post(url_gateway + `/multi-server/account/backoffice-rights/add`,
            data
            , {headers});
        return res.data
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const addApplicativesRightsForSelectedAccount = async (data: ApplicativeUpdateRightsProps, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<ApplicativeAccountsProps> = await axios.post(url_gateway + `/multi-server/account/applicative-rights/add`,
            data
            , {headers});
        return res.data
    } catch (error) {
        toastError();
    }
}
export const deleteApplicativeRightsForSelectedAccount = async (login: string, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<ApplicativeAccountsProps> = await axios.delete(url_gateway + `/multi-server/account/applicative-rights/by-login/${login}`
            , {headers});
        return res.data;
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const updateApplicativeRightsForSelectedAccount = async (data: ApplicativeUpdateRightsProps, token: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ${token}`,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse<ApplicativeAccountsProps> = await axios.put(url_gateway + `/multi-server/account/applicative-rights/update`,
            data
            , {headers});
        return res.data;
    } catch (error) {
        toastError();
        <Navigate to={APP_PATH.ROOT}/>;
    }
}

export const validateToken = async (jwt?: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ` + jwt,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.post(url_gateway + "/auth/validate-set-or-reset-password-token"
            , {token: jwt}
            , {headers});
        return res.data;
    } catch (error) {
        console.error("Error:", error);
    }
}

export const setPasswordWithToken = async (jwt?: string, newPassword?: string) => {
    const headers = {
        accept: "application/json",
        Authorization:
            `Bearer ` + jwt,
        servercode: serverCode
    };
    try {
        const res: AxiosResponse = await axios.post(url_gateway + "/auth/set-or-reset-password"
            , {newPassword: newPassword}
            , {headers});
        return res.data;
    } catch (error) {
        console.error("Error:", error);
    }
}
import { InputContainer, InputText } from "./Style";
import {ChangeEvent, Dispatch} from "react";
import eyeOpenImage from "../../Assets/Images/eye-open.png";
import eyeCloseImage from "../../Assets/Images/eye-close.png";

export interface InputProps {
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
    label?: string;
    type?: string;
    icon?: string;
    passwordVisible?: boolean
    setPasswordVisible?: Dispatch<boolean>
    dataTestId?: string;
}

const Input = ({
                   value,
                   icon,
                   onChange,
                   onKeyPress,
                   placeholder,
                   className,
                   type,
                   passwordVisible,
                   setPasswordVisible,
                   dataTestId
               }: InputProps) => {

    const togglePasswordVisibility = () => {
        setPasswordVisible && setPasswordVisible(!passwordVisible);
    };

    return (
        <InputContainer>
      <span className="p-input-icon-left">
          <i className={icon}/>
        {type === "password" && (
            <img
                src={passwordVisible ? eyeOpenImage : eyeCloseImage}
                alt="Toggle password visibility"
                onClick={togglePasswordVisibility}
                style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "18px",
                    height: "18px",
                }}
            />
        )}
          <InputText
              value={value}
              onChange={onChange}
              onKeyPress={onKeyPress}
              placeholder={placeholder}
              className={className}
              data-testid={dataTestId}
              type={type === "password" && passwordVisible ? "text" : type}
          />
      </span>
        </InputContainer>
    );
};

export default Input;

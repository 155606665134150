import styled from "styled-components";

export const FullPageContainer = styled.div`
    position:fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;

    display : flex;
    flex-direction : row;
    align-items : center;
    
    .afterResetPassword {
        flex : 1;
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 350px;
    }
    
    .afterResetPassword h3 {
        color : ${({theme}) => theme.color_action};
    }
    
    .afterResetPassword h4 {
        text-align: left;
        text-decoration: underline;
    }
    
    .afterResetPassword footer{
        font-size:14px;
        font-family: "Assistant", sans-serif;
        color:${({theme}) => theme.color_grey__600};
        position:absolute;
        bottom:0;
        margin-bottom: 0.8%;
        user-select: none;
    }
    
    .scrollable-content {
        max-height: 13.2vh;
        overflow-y: auto;
    }
    
    @media screen and (max-height: 650px) {
        footer {
            display: none;
        }
    }
}`

export const Line = styled.div`
    svg {
        background-color:${({theme}) => theme.color_white};
    }  

    path {
        fill:${({theme}) => theme.color_authentication_background};
    }
`


export const LandingPageContainer = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background-color:${({theme}) => theme.color_authentication_background};
    flex : 1;

    .hero__figure {
        position: relative;
        width: 353px;
        transform: scale(0.7);
    }

/* ----------------------------- */
/* ==ANIMATION BUBBLES           */
/* ----------------------------- */

.animation-bulles {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}

.animation-bulles img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.animation-1 {
    height: 17.25664%;
    width: 14.78439%;
    top: 7.65487%;
    left: 6.46817%;
}

.animation-2 {
    height: 21.23894%;
    width: 16.83778%;
    top: 0;
    left: 44.35318%;
}

.animation-3 {
    height: 19.69027%;
    width: 11.29363%;
    top: 38.71681%;
    left: 73.59343%;
}

.animation-4 {
    height: 19.02655%;
    width: 11.0883%;
    top: 68.07522%;
    left: 48.76797%;
}

.animation-5 {
    height: 19.9115%;
    width: 12.52567%;
    top: 47.92035%;
    left: 9.67146%;
}

.animation-1 .animation-bulles-blue,
.animation-2 .animation-bulles-blue,
.animation-3 .animation-bulles-blue,
.animation-4 .animation-bulles-blue,
.animation-5 .animation-bulles-blue {
    -webkit-animation: dydu-anim-fadeInOutUp-blue 5s ease-out infinite;
    animation: dydu-anim-fadeInOutUp-blue 5s ease-out infinite;
}

.animation-1 .animation-bulles-green,
.animation-2 .animation-bulles-green,
.animation-3 .animation-bulles-green,
.animation-4 .animation-bulles-green,
.animation-5 .animation-bulles-green {
    -webkit-animation: dydu-anim-fadeInOutUp-green 5s ease-out infinite;
    animation: dydu-anim-fadeInOutUp-green 5s ease-out infinite;
}

.animation-2 .animation-bulles-blue,
.animation-2 .animation-bulles-green {
    -webkit-animation-delay: 4s;
    animation-delay: 4s
}

.animation-3 .animation-bulles-blue,
.animation-3 .animation-bulles-green {
    -webkit-animation-delay: 7s;
    animation-delay: 7s
}

.animation-4 .animation-bulles-blue,
.animation-4 .animation-bulles-green {
    -webkit-animation-delay: 6s;
    animation-delay: 6s
}

.animation-5 .animation-bulles-blue,
.animation-5 .animation-bulles-green {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

/* BLUE ANIMATION */

@-webkit-keyframes dydu-anim-fadeInOutUp-blue {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }

    14% {
        opacity: 1
    }

    35% {
        opacity: 1
    }

    40% {
        opacity: 0;
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px)
    }

    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0
    }

    to {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@keyframes dydu-anim-fadeInOutUp-blue {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }

    14% {
        opacity: 1
    }

    35% {
        opacity: 1
    }

    40% {
        opacity: 0;
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px)
    }

    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 0
    }

    to {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

/*  GREEN ANIMATION */

@-webkit-keyframes dydu-anim-fadeInOutUp-green {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }

    10% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }

    24% {
        opacity: 1
    }

    45% {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px)
    }

    to {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@keyframes dydu-anim-fadeInOutUp-green {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }

    10% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }

    24% {
        opacity: 1
    }

    45% {
        opacity: 1
    }

    50% {
        opacity: 0;
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px)
    }

    to {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}`
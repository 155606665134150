import {ApplicativeAccountsProps, ApplicativeUpdateRightsProps} from "../../../Tools/Interfaces";
import {ChangeEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {DataTableValue} from "primereact/datatable";
import Button from "../../../Components/Button/Button";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import {BmsManagementContainer} from "../Style";
import Input from "../../../Components/Input/Input";
import {updateApplicativeRightsForSelectedAccount} from "../../../Tools/Api";
import {isListEmpty} from "../../../Tools/Utilities";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../Contexts/UserContext";
import {toastSuccessUpdatingAccount} from "../../../Tools/Toast";


interface AccountsWithBmsRightsProps {
    applicativeAccounts: ApplicativeAccountsProps[];

}

const AccountsWithBmsRights = ({applicativeAccounts}: AccountsWithBmsRightsProps) => {
    const serverId = useParams<{ serverId: string }>();
    const [accountsWithBmsRightsOnSelectedServer, setAccountsWithBmsRightsOnSelectedServer] = useState<DataTableValue[]>([]);
    const [checkedAccounts, setCheckedAccounts] = useState<DataTableValue[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const {t} = useTranslation();
    const {casClient} = useUserContext();

    useEffect(() => {
        const accountsWithBmsRightsOnSelectedServer = applicativeAccounts?.filter((account: ApplicativeUpdateRightsProps) => {
            return account?.applicativeRights?.accountServersWithBmsAccess?.find((server: {
                serverId?: string, buType?: number
            }) => server.serverId === serverId.serverId);
        });
        setAccountsWithBmsRightsOnSelectedServer(accountsWithBmsRightsOnSelectedServer)
    }, [applicativeAccounts])

    const handleSelect = (e: CheckboxChangeEvent) => {
        let _checkedAccounts = [...checkedAccounts];
        if (e.checked) {
            _checkedAccounts.push(e.value);
        } else {
            _checkedAccounts = _checkedAccounts.filter((account) => account.login !== e.value.login);
        }
        setCheckedAccounts(_checkedAccounts)
    }

    const handleDeleteBmsRights = () => {
        const accountToDeleteBmsRights = checkedAccounts.map((account) => {
            return {
                login: account.login,
                applicativeRights: {
                    accountServersWithBmsAccess: account.applicativeRights.accountServersWithBmsAccess.filter((server: {
                        serverId?: string
                    }) => server.serverId !== serverId.serverId)
                }
            }
        });
        accountToDeleteBmsRights.forEach((account) => {
            updateApplicativeRightsForSelectedAccount(account, casClient.getToken()).then(() => {
                toastSuccessUpdatingAccount(t('general.Toasts.successUpdate'))
                setAccountsWithBmsRightsOnSelectedServer(accountsWithBmsRightsOnSelectedServer.filter((accountWithBmsRights) => accountWithBmsRights.login !== account.login));
            });
        })
        setCheckedAccounts([])
    }
    const handleCheckedAllOrNone = () => {
        if (checkedAccounts.length > 0) {
            setCheckedAccounts([]);
        } else {
            setCheckedAccounts(filteredData);
        }
    }
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredData = accountsWithBmsRightsOnSelectedServer?.filter((account: unknown) => {
        return account && Object.values(account).some(
            (value) =>
                typeof value === "string" && value.toLowerCase().includes(searchTerm)
        );
    });


    return (
        <BmsManagementContainer>
            <h3>{t('general.ServerManagement.titleBms')}</h3>
            <div className="search-container">
                <Input className="input-search" value={searchTerm} placeholder={t('general.Input.search')}
                       onChange={handleSearch}
                       icon="pi pi-search"/>
            </div>
            {filteredData?.length > 0 ? (
                    <>
                        <div className='userlist-container'>
                            <div>
                                <Checkbox checked={!isListEmpty(checkedAccounts)}
                                          onChange={handleCheckedAllOrNone} id="check-all"/>
                            </div>
                            <div className="accountsListContainer">
                                {filteredData?.map((account) => {
                                    return (
                                        <ul className="accounts-list" key={account.login}>
                                            <li key={account.login} className="account">
                                                <label className="ml-2">
                                                    {account.login}
                                                </label>
                                                <Checkbox key={account.login} inputId={account.login} value={account}
                                                          id={account.login}
                                                          name={'server'}
                                                          onChange={handleSelect}
                                                          checked={checkedAccounts.some((_account) => _account.login === account.login)}/>
                                            </li>
                                        </ul>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div>
                                <Button icon="pi pi-check" aria-label="Filter" label={t('general.Button.delete')}
                                        className="button-delete"
                                        onClick={handleDeleteBmsRights} disabled={isListEmpty(checkedAccounts)}/>
                            </div>
                        </div>
                    </>)
                :
                (
                    <div>
                        <p>{t('general.ServerManagement.noUser')}</p>
                    </div>)
            }
        </BmsManagementContainer>
    );
}

export default AccountsWithBmsRights;
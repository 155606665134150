import heroBanner from "src/Assets/Images/hero-banner.svg";
import heroBanner1Blue from "src/Assets/Images/hero-banner-01-blue.svg";
import heroBanner1Green from "src/Assets/Images/hero-banner-01-green.svg";
import heroBanner2Blue from "src/Assets/Images/hero-banner-02-blue.svg";
import heroBanner2Green from "src/Assets/Images/hero-banner-02-green.svg";
import heroBanner3Blue from "src/Assets/Images/hero-banner-03-blue.svg";
import heroBanner3Green from "src/Assets/Images/hero-banner-03-green.svg";
import heroBanner4Blue from "src/Assets/Images/hero-banner-04-blue.svg";
import heroBanner4Green from "src/Assets/Images/hero-banner-04-green.svg";
import heroBanner5Blue from "src/Assets/Images/hero-banner-05-blue.svg";
import heroBanner5Green from "src/Assets/Images/hero-banner-05-green.svg";
import dyduLogo from "src/Assets/Images/dydu_logo_full.png";
import {FullPageContainer, LandingPageContainer, Line} from "./Style";
import Button from "src/Components/Button/Button";
import Input from "../../Components/Input/Input";
import React, {useEffect, useState} from "react";
import {
    toastPasswordGeneratedAndCopiedToClipboard,
    toastPasswordDontMatch,
    toastPasswordNotStrong,
    toastErrorModifiedPassword
} from "../../Tools/Toast";
import {setPasswordWithToken, validateToken} from "../../Tools/Api";
import {APP_PATH} from "../../Tools/Constants";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface AccessRightEnumMap {
    [key: string]: string[];
}

const SetOrResetPassword = () => {
    console.log('set or reset passowrd page');
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = new URL(window.location.href.replace('/#/', '/'));
    const urlSearchParams = new URLSearchParams(location.search);
    const jwt = urlSearchParams.get('token') || "";
    const service = urlSearchParams.get('service') || "";

    const currentYear = new Date().getFullYear();
    const MIN_PASSWORD_LENGTH = 16;
    const DIGIT = RegExp("[0-9]");
    const SPECIAL_CHARS = "!\"#$%&'()*+,-./:;<=>?@[]^_{|}~";
    const CHARS = SPECIAL_CHARS + "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [redirecting, setRedirecting] = useState(true);
    useEffect(() => {
        validateToken(jwt)
            .then(result => {
                if (result !== 'VALID_TOKEN') {
                    navigate(APP_PATH.LINK_EXPIRED, {
                        state: {linkExpired: true},
                    });
                }
            })
            .catch(error => {
                console.error("Error :", error);
            })
            .finally(() => {
                    setRedirecting(false);
            });
        const passwordField = document.querySelector('.password') as HTMLInputElement;
        const confirmationField = document.querySelector('.confirmPassword') as HTMLInputElement;
        if (passwordField) {
            passwordField.value = "";
            passwordField.addEventListener('change', onPasswordChange);
            passwordField.addEventListener('keyup', onPasswordChange);
        }
        if (confirmationField) {
            confirmationField.value = "";
            confirmationField.addEventListener('change', onConfirmationChange);
            confirmationField.addEventListener('keyup', onConfirmationChange);
        }
        return () => {
            // Cleanup event listeners when component unmounts
            if (passwordField) {
                passwordField.removeEventListener('change', onPasswordChange);
                passwordField.removeEventListener('keyup', onPasswordChange);
            }

            if (confirmationField) {
                confirmationField.removeEventListener('change', onConfirmationChange);
                confirmationField.removeEventListener('keyup', onConfirmationChange);
            }
        };
    }, [redirecting]);

    const unmarkInput = (input: { classList: DOMTokenList }) => {
        input.classList.remove('input_valid');
        input.classList.remove('input_invalid');
    };

    const markInputValid = (input: { classList: DOMTokenList }) => {
        input.classList.add('input_valid');
        input.classList.remove('input_invalid');
    };

    const markInputInvalid = (input: { classList: DOMTokenList }) => {
        input.classList.add('input_invalid');
        input.classList.remove('input_valid');
    };

    const isPasswordStrong = function (password: string) {
        if (password == null || password.length < MIN_PASSWORD_LENGTH) {
            return false;
        }

        if (password.toLowerCase() === password || password.toUpperCase() === password) {
            return false;
        }

        if (!DIGIT.test(password)) {
            return false;
        }

        for (let i = 0; i < SPECIAL_CHARS.length; i++) {
            if (password.indexOf(SPECIAL_CHARS[i]) !== -1) {
                return true;
            }
        }

        return false;
    };

    const onPasswordChange = () => {
        try {
            const passwordField = document.querySelector('.password') as HTMLInputElement;
            const password = passwordField.value;
            if (password === '') {
                unmarkInput(passwordField);
            } else if (isPasswordStrong(password)) {
                markInputValid(passwordField);
            } else {
                markInputInvalid(passwordField);
            }
            onConfirmationChange();
        } catch (e) {
            console.error('ReinitPassword : Error in onPasswordChange ' + e, e);
        }
    };

    const onConfirmationChange = function () {
        try {
            const passwordField = document.querySelector('.password') as HTMLInputElement;
            const confirmationField = document.querySelector('.confirmPassword') as HTMLInputElement;
            const password = passwordField.value;
            const confirmation = confirmationField.value;
            if (confirmation === '' || !isPasswordStrong(password)) {
                unmarkInput(confirmationField);
            } else if (confirmation === password) {
                markInputValid(confirmationField);
            } else {
                markInputInvalid(confirmationField);
            }
        } catch (e) {
            console.error('ReinitPassword : Error in onConfirmationChange ' + e, e);
        }
    };

    const generatePassword = function () {
        let result = "";
        for (let index = 0; index < MIN_PASSWORD_LENGTH; index++) {
            const char = CHARS[Math.floor(Math.random() * Math.floor(CHARS.length))];
            result += char;
        }
        return result;
    };

    const suggestPassword = () => {
        try {
            // Assuming you have references to the input fields
            const passwordField = document.querySelector('.password') as HTMLInputElement;
            const confirmationField = document.querySelector('.confirmPassword') as HTMLInputElement;

            if (passwordField && confirmationField) {
                passwordField.type = 'text';
                confirmationField.type = 'text';

                let password = generatePassword();
                while (!isPasswordStrong(password)) {
                    password = generatePassword();
                }

                passwordField.value = password;
                confirmationField.value = password;
                setPasswordVisible(true)

                onPasswordChange();
                if (navigator && navigator.clipboard) {
                    navigator.clipboard.writeText(password);
                    toastPasswordGeneratedAndCopiedToClipboard(t('general.PasswordManagement.Toast.toastPasswordGeneratedAndCopiedToClipboard'));
                } else {
                    console.warn('ReinitPassword: Unable to access navigator.clipboard');
                }
            } else {
                console.warn('ReinitPassword: Unable to find input fields');
            }
        } catch (e) {
            console.error('ReinitPassword: Error in suggestPassword ' + e, e);
        }
    };

    const redirectBasedOnAccessRights = (accessRightEnumMap:AccessRightEnumMap) => {
        const urlToRedirect = Object.values(accessRightEnumMap)[0][0];
        if (Object.keys(accessRightEnumMap)[0] === 'BO') {
            window.location.href = `https://${urlToRedirect}/bo`;
        } else if (Object.keys(accessRightEnumMap)[0] === 'BMS') {
            window.location.href = `https://${urlToRedirect}`;
        } else {
            navigate(APP_PATH.AUTH);
        }
    }

    const validateTokenAndSetPassword = () => {
        const newPassword = document.querySelector('.confirmPassword') as HTMLInputElement;
        setPasswordWithToken(jwt, newPassword.value).then((res) => {
            let type;
            let openingBraceIndex;
            if (res !== undefined) {
                openingBraceIndex = res.indexOf('{');
                type = res.slice(0, openingBraceIndex).trim();
                res = res.slice(openingBraceIndex);
            }
            if (type === 'setPassword') {
                const accessRightEnumMap:AccessRightEnumMap = JSON.parse(res);
                if (Object.values(accessRightEnumMap).length === 1) {
                    redirectBasedOnAccessRights(accessRightEnumMap);
                } else {
                    navigate(APP_PATH.APPLICATION_ACCESS, {
                        state: {accessRightEnumMap: accessRightEnumMap},
                    });
                }
            } else if (type === 'resetPassword' && service !== "") {
                navigate(APP_PATH.PASSWORD_MODIFIED, {
                    state: {urlToRedirect: service},
                });
            } else {
                toastErrorModifiedPassword(t('general.PasswordManagement.Toast.toastErrorModifiedPassword'));
            }
        });
    }

    const checkInputsToSubmit = function () {
        try {
            const passwordField = document.querySelector('.password') as HTMLInputElement;
            const confirmationField = document.querySelector('.confirmPassword') as HTMLInputElement;
            const password = passwordField.value;
            const confirmation = confirmationField.value;
            if (!isPasswordStrong(password)) {
                toastPasswordNotStrong(t('general.PasswordManagement.Toast.toastPasswordNotStrong'));
            } else if (confirmation === password) {
                validateTokenAndSetPassword();
            } else {
                toastPasswordDontMatch(t('general.PasswordManagement.Toast.toastPasswordDontMatch'));
            }
        } catch (e) {
            console.error('ReinitPassword : Error in checkInputsToSubmit ' + e, e);
        }
    };

    if (redirecting) {
        return null;
    }
    return (
        <FullPageContainer>
            <div className="formResetPassword">
                <div className="logo">
                    <img src={dyduLogo} height="69" width="150"
                         alt="DYDU" className="flex1"/>
                </div>
                <h1>{t('general.PasswordManagement.ResetPasswordPage.title')}</h1>
                <div className="passwordMustContain">
                    <p>{t('general.PasswordManagement.ResetPasswordPage.mustContain')}</p>
                    <ul className="list-bullet">
                        <li>{t('general.PasswordManagement.ResetPasswordPage.16Characteres')}</li>
                        <li>{t('general.PasswordManagement.ResetPasswordPage.1Upper')}</li>
                        <li>{t('general.PasswordManagement.ResetPasswordPage.1Lower')}</li>
                        <li>{t('general.PasswordManagement.ResetPasswordPage.1Special')}
                            <br/>{t('general.PasswordManagement.ResetPasswordPage.specialList')}
                        </li>
                        <li>{t('general.PasswordManagement.ResetPasswordPage.1Number')}</li>
                    </ul>
                </div>
                <form id="passwordReinitForm" className="connectionform passwordReinitForm">
                    <br/>
                    <Input placeholder={t('general.PasswordManagement.ResetPasswordPage.newPassword')} className="password" type="password"
                           passwordVisible={passwordVisible} setPasswordVisible={setPasswordVisible}></Input>
                    <br/>
                    <Input placeholder={t('general.PasswordManagement.ResetPasswordPage.confirmPassword')} className="confirmPassword" type="password"
                           passwordVisible={passwordVisible} setPasswordVisible={setPasswordVisible}></Input>
                </form>
                <br/>
                <Button label={t('general.PasswordManagement.ResetPasswordPage.suggestPassword')} className="button1 button" onClick={suggestPassword}/>
                <Button label={t('general.PasswordManagement.ResetPasswordPage.resetPassword')} className="button2 button" onClick={checkInputsToSubmit}/>
                <footer>
                    © 2009 - {currentYear} dydu. {t('general.PasswordManagement.ResetPasswordPage.allRightsReserved')}
                </footer>
            </div>
            <Line>
                <svg preserveAspectRatio="none" height="100%" width="50" version="1.1" viewBox="0 720 48 1440"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M 48,2880 V 1.05e-5 H 0 V 720.00001 C 110,1437.5 0,2160 0,2160 v 720 z"></path>
                </svg>
            </Line>
            <LandingPageContainer>
                <div className="landinganimation">
                    <figure className="hero__figure">
                        <img src={heroBanner} height="326" width="353"
                             alt="image dydu software"/>
                        <div className="animation-bulles animation-1">
                            <img className="animation-bulles-blue" src={heroBanner1Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner1Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-2">
                            <img className="animation-bulles-blue" src={heroBanner2Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner2Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-3">
                            <img className="animation-bulles-blue" src={heroBanner3Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner3Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-4">
                            <img className="animation-bulles-blue" src={heroBanner4Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner4Green}
                                 alt="Bubble green"/>
                        </div>
                        <div className="animation-bulles animation-5">
                            <img className="animation-bulles-blue" src={heroBanner5Blue}
                                 alt="Bubble blue"/>
                            <img className="animation-bulles-green" src={heroBanner5Green}
                                 alt="Bubble green"/>
                        </div>
                    </figure>
                </div>
            </LandingPageContainer>
        </FullPageContainer>
    );
};

export default SetOrResetPassword;
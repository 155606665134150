import {Navigate, useRoutes} from "react-router-dom";
import {APP_PATH} from "../Tools/Constants";
import BackOfficeAccountsWithLayout from "./BackOfficeAccounts/BackOfficeAccountsWithLayout";
import ApplicativeAccountsManagementWithLayout
    from "./ApplicativeAccountsManagement/ApplicativeAccountsManagementWithLayout";
import HomePageWithLayout from "./HomePage/HomePageWithLayout";
import Login from "./Auth/Login";
import ApplicativeServersManagementWithLayout
    from "./ApplicativeServersManagement/ApplicativeServersManagementWithLayout";
import {useUserContext} from "../Contexts/UserContext";
import Auth from "./Auth/Auth";
import Logout from "./Auth/Logout";
import {encodedServiceUrl} from "../Tools/Api";
import Blank from "./Blank/Blank";
import SetOrResetPassword from "./SetOrResetPassword/SetOrResetPassword";
import ApplicationAccess from "./ApplicationAccess/ApplicationAccess";
import PasswordModified from "./PasswordModified/PasswordModified";
import LinkExpired from "./LinkExpired/LinkExpired";


export const Router = () => {
    const {casClient} = useUserContext();

    console.log('router authent ' + (casClient.getToken() ? 'yes ' : 'no ') + window.location.href);

    (async () => {
        // only usefull on callback call to validate ticket and persist token in session
        await casClient.checkSession(false, encodedServiceUrl);
    })();

    const unprotectedRoutes = useRoutes([
        { path: APP_PATH.AUTH, element: <Auth /> },
        { path: APP_PATH.CALLBACK, element: <Blank /> },
        {path: APP_PATH.ROOT, element: <Login/>},
        {path: APP_PATH.SET_OR_RESET_PASSWORD, element: <SetOrResetPassword/>},
        {path: APP_PATH.APPLICATION_ACCESS, element: <ApplicationAccess/>},
        {path: APP_PATH.PASSWORD_MODIFIED, element: <PasswordModified/>},
        {path: APP_PATH.LINK_EXPIRED, element: <LinkExpired/>},
        {path: '*', element: <Navigate to={APP_PATH.ROOT}/>},
    ]);

    const protectedRoutes = useRoutes([
        { path: APP_PATH.LOGOUT, element: <Logout /> },
        {path: APP_PATH.ROOT, element: <HomePageWithLayout/>},
        {path: APP_PATH.SET_OR_RESET_PASSWORD, element: <SetOrResetPassword/>},
        {path: APP_PATH.APPLICATION_ACCESS, element: <ApplicationAccess/>},
        {path: APP_PATH.PASSWORD_MODIFIED, element: <PasswordModified/>},
        {path: APP_PATH.LINK_EXPIRED, element: <LinkExpired/>},
        {path: APP_PATH.BACKOFFICE_ACCOUNTS, element: <BackOfficeAccountsWithLayout/>},
        {path: APP_PATH.APPLICATIVE_ACCOUNTS_MANAGEMENT, element: <ApplicativeAccountsManagementWithLayout/>},
        {path: APP_PATH.APPLICATIVE_SERVERS_MANAGEMENT, element: <ApplicativeServersManagementWithLayout/>},
        {path: '*', element: <Navigate to={APP_PATH.ROOT}/>},
    ]);

    return casClient.getToken() ? protectedRoutes : unprotectedRoutes;
}
